export default {
  classList: '/course/supply/class/query/list', // 班级列表
  classStatus: '/course/supply/class/updateClassAvailableStatus', //修改班级可用状态
  classdataStatistics: '/course/supply/class/dataStatistics', //班级统计数据
  classCreate: '/course/supply/class/create', // 创建班级
  classUpdate: '/course/supply/class/update', // 编辑班级
  classInfo: '/course/supply/class/query', // 班级详情（编辑页面）
  classhasCourse: '/course/supply/class/courses/list', //班级已有课程
  addOwnCourse: '/course/supply/class/course/addOwn', //添加课程
  courseDelete: '/course/supply/class/course/batchRemove', //批量删除
  courseSingDelete: '/course/supply/class/course/remove', //单个课程删除
  classPlans: '/course/supply/class/study/plan', //班级学习计划
  studentinfo: '/course/supply/class/query/student/study/info/list', //学员信息
  classConfirm: '/course/supply/class/confirm', //确认开班
  classcancel: '/course/supply/class/cancel', //取消班级
  studyHistory: '/course/supply/class/student/personal/studyHistory', //学习详情
  headteacherList: '/course/supply/class/headteacher/select/list', // 查询班主任列表
  classDetail: '/course/supply/class/query/details', //班级详情页面
  changeClassStatus: '/course/supply/class/change/classstatus', //已设置对外招生
  studentInfo: '/course/supply/class/student/studyHistory', //班级学习详情
  classfinish: '/course/supply/class/finish', //结训完成

  classSelect: '/course/manage/classes/select', // 班级下拉列表
  classStudents: '/course/manage/class/students/page', // 班级已有学员
  studentsByOrg: '/course/manage/class/student/add/listByOrg', // 按学员列表添加-学员列表
  studentsByClass: '/course/manage/class/student/add/listByClass', // 按现有班级添加-学员列表
  studentsClasses: '/course/manage/class/student/add/listClasses', // 按现有班级添加-班级列表
  studentsRemove: '/course/manage/class/students/remove', // 删除学员
  studentsAdd: '/course/manage/class/students/add', // 添加学员
  classCourses: '/course/manage/class/courses/list', // 班级已有课程
  classCourseAddOwn: '/course/manage/class/course/addOwn', // 班级添加自建课程
  classSettleCourseList: '/course/manage/class/settle/course/list', // 班级结算-课程列表
  classRemove: '/course/manage/class/course/remove', // 删除班级课程
  classOffer: '/course/manage/class/start', // 开班
}
