<template>
  <div class="coursePlans-list">
    <table-list
      title="已有课程列表"
      :loading="loading"
      :data="courseList"
      :columns="columns(this)"
      :operates="operates(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
    <div class="Course-btn">
      <span @click="handlePathTo">确定提交</span>
    </div>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '从课程库选择',
    method: _this.handleallCourse,
  },
  {
    label: '批量删除',
    method: _this.handleManyDelete,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'courseId',
    label: '课程编号',
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'courseCategory',
    label: '课程类型',
  },
  {
    prop: 'durationCount',
    label: '课程时长',
  },
  {
    prop: 'sectionsTotal',
    label: '章节数',
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '删除',
    method: _this.handleDelete,
  },
]
import TableList from '@/components/TableList'
import to from 'await-to'
import { classhasCourse, courseDelete, courseSingDelete } from '@/api/class'
export default {
  name: 'CourseAdd',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      columns,
      operates,
      loading: false,
      courseList: [],
      selectCourse: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
        classId: this.$route.query.classId,
      },
    }
  },
  mounted() {
    this.getCourse()
  },
  methods: {
    async getCourse() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(classhasCourse({ ...this.pager }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.courseList = res.data.list
      this.pager.total = res.data.total
    },
    handleallCourse() {
      this.$router.push({
        path: '/course/classManger/coursePlans',
        query: { classId: this.pager.classId },
      })
    },
    handleManyDelete() {
      if (!this.selectCourse.length) return this.$message.warning('请先选择要删除的课程')
      let classCourseIds = []
      this.selectCourse.map(item => classCourseIds.push(item.classCourseId))
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(
          courseDelete({ classId: this.pager.classId, classCourseIds: classCourseIds }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourse()
      })
    },
    handleDelete(row) {
      this.$confirm('确认删除课程吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(
          courseSingDelete({ classId: row.classId, classCourseId: row.classCourseId }),
        )
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getCourse()
      })
    },
    // 多选
    handleSelectionChange(val) {
      this.selectCourse = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getCourse()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getCourse()
    },
    handlePathTo() {
      this.$router.push({
        path: '/course/class',
      })
    },
  },
}
</script>
<style scoped>
.Course-btn {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.Course-btn span {
  display: block;
  cursor: pointer;
  width: 110px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 40px;
}
</style>
