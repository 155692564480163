<!--
 * @Description: 选择班主任
 * @Author: xiawenlong
 * @Date: 2021-04-22 10:06:14
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-06-14 12:33:49
-->
<template>
  <el-dialog :close-on-click-modal="false" class="student-invite" :visible.sync="dialogVisible">
    <div class="title">选择班主任</div>
    <div class="close-icon" @click="dialogVisible = false">
      <i class="el-icon-close"></i>
    </div>
    <div class="content">
      <table-list
        ref="headTeacherTable"
        :loading="loading"
        :data="headteacherList"
        :search-form="searchForm(this)"
        :columns="columns(this)"
        :options="{ selection: true, reserveSelection: true, rowKey: 'headTeacherId' }"
        @search="onSearch"
        @selectionChange="handleSelectionChange"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></table-list>
      <div class="btns">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
const searchForm = () => [
  {
    label: '姓名进行搜索',
    prop: 'teacherName',
    width: 150,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'headTeacherName',
    label: '姓名',
  },
  // {
  //   prop: 'headTeacherId',
  //   label: '证件号',
  // },
]
import TableList from '@/components/TableList'
import { headteacherList } from '@/api/class'
import to from 'await-to'
export default {
  name: 'ClassAdviserDialog',
  components: { TableList },
  data() {
    return {
      searchForm,
      columns,
      dialogVisible: false,
      loading: false,
      headteacherList: [],
      selectTeachers: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
    }
  },
  methods: {
    show(select = []) {
      this.dialogVisible = true
      this.pager.current = 1
      this.getHeadTeacherList()
      this.$nextTick(() => {
        this.$refs.headTeacherTable.$refs.tableList.clearSelection()
        if (select.length) {
          select.forEach(row => {
            this.$refs.headTeacherTable.$refs.tableList.toggleRowSelection(row)
          })
        }
      })
    },
    async getHeadTeacherList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        headteacherList({ classId: this.$route.query.classId, current, size, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.headteacherList = res.data
      this.pager.total = res.data.total
    },
    submit() {
      if (this.selectTeachers.length > 3) {
        return this.$message.warning('最多选择三个班主任')
      }
      this.$emit('handleTeachersChange', this.selectTeachers)
      this.dialogVisible = false
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getHeadTeacherList()
    },
    // 多选
    handleSelectionChange(val) {
      this.selectTeachers = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getHeadTeacherList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getHeadTeacherList()
    },
  },
}
</script>
<style lang="scss" scoped>
.student-invite {
  ::v-deep.el-dialog {
    position: relative;
    width: 860px;
    background: #ffffff;
    border-radius: 0;
    color: #4d4e5d;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 6px;
      background: #ff7b33;
    }
    .close-icon {
      z-index: 99;
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 14px;
      font-size: 25px;
      font-weight: bold;
      color: #8c8c8c;
    }
    .el-dialog__header {
      display: none;
    }
    .el-tabs__header {
      margin-bottom: 26px;
      padding-top: 23px;
    }
    .el-dialog__body {
      padding: 0;
    }
    .title {
      height: 62px;
      line-height: 62px;
      border-bottom: 1px solid #c9c9c9;
      padding-left: 30px;
      font-size: 18px;
    }
    .content {
      padding: 30px 10px;
      > .btns {
        text-align: right;
        padding-top: 30px;
      }
    }
  }
}
</style>
