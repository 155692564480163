<template>
  <div class="class-info">
    <div class="main">
      <div class="classinfos">
        <el-card class="hei">
          <div slot="header" class="clearfix">
            <span class="title">{{ '班级信息' }}</span>
            <span class="classtatus">{{ status }}</span>
          </div>
          <div class="details">
            <img :src="classInf.pic" />
            <!-- <p>班级编号：{{ classInf.classId }}</p> -->
            <p>班级名称：{{ classInf.className }}</p>
            <p>班级人数：{{ classInf.classStudentCount }}</p>
            <p>班主任：{{ classInf.headTeacherNames }}</p>
            <p>培训时间：{{ classInf.classBeginTime }}~{{ classInf.classEndTime }}</p>
            <p>班级介绍：{{ classInf.introduction }}</p>
          </div>
        </el-card>
      </div>
      <div class="courseinfos">
        <el-card class="hei">
          <div slot="header" class="clearfix">
            <span class="title">{{ '课程信息' }}</span>
          </div>
          <div class="course-pan">
            <el-tabs v-model="activeName">
              <el-tab-pane
                v-for="(item, index) in course"
                :key="index"
                :label="item.courseName"
                :name="item.id"
              >
                <div class="items-ct">
                  <p class="items-js">
                    讲师：
                    {{ item.courseTeacherNames }}
                  </p>
                  <div class="items-video">
                    <p v-for="(items, indexs) in item.sectionInfoList" :key="indexs">
                      {{ indexs + 1 }}、 {{ items.sectionName }} （{{ items.durationMinutes }}分钟）
                    </p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </div>
    </div>
    <div class="StudentList">
      <el-card class="hei">
        <div slot="header" class="clearfix">
          <span class="title">{{ '学员信息' }}</span>
        </div>
        <table-list
          :loading="loading"
          :data="studentList"
          :columns="columns(this)"
          :pager="pager"
          :options="{ selection: false }"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></table-list>
      </el-card>
    </div>
  </div>
</template>
<script>
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '学员名称',
  },
  {
    prop: 'mobile',
    label: '手机号码',
  },
  {
    prop: 'userCreateTime',
    label: '报名时间',
  },
  {
    prop: 'studyDurationSumMinutes',
    label: '累计学习时长（分钟）',
  },
  {
    prop: 'lastStudyTime',
    label: '最后一次登录时间',
  },
]
import TableList from '@/components/TableList'
import to from 'await-to'
import { classDetail, classPlans, studentinfo } from '@/api/class'
export default {
  name: 'Classinfo',
  components: {
    TableList,
  },
  data() {
    return {
      classId: this.$route.query.classId,
      //0-编辑中(待开班)，1-待培训（待上架），2-培训中，3-已结训（已完成）
      status: this.$route.query.status,
      // statusText: '',
      classInf: {},
      course: [],
      activeName: '1',
      studentList: [],
      columns,
      loading: false,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
    }
  },
  mounted() {
    this.ClassinfoData()
    this.ClassPlans()
    this.StudentData()
  },
  methods: {
    //获取班级信息
    async ClassinfoData() {
      const [res, err] = await to(classDetail({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.classInf = res.data
    },
    //获取课程信息
    async ClassPlans() {
      const [res, err] = await to(classPlans({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.course = res.data
      for (let i of this.course) {
        i.id = '0'
      }
      for (let i = 0; i < this.course.length; i++) {
        this.course[i].id = (i + 1).toString()
      }
    },
    //获取学员列表
    async StudentData() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(studentinfo({ current, size, classId: this.classId }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.studentList = res.data.list
      this.pager.total = res.data.total
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.StudentData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.StudentData()
    },
  },
}
</script>
<style scoped>
.hei {
  height: 100%;
}
.main {
  display: flex;
}
.classinfos {
  width: 412px;
}
.clearfix {
  position: relative;
}
.classtatus {
  position: absolute;
  right: 0;
  font-size: 18px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #ff4b36;
}
.details {
  margin-left: 20px;
}
.details img {
  width: 282px;
  height: 145px;
  margin-bottom: 20px;
}
.details p {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 20px;
}
.courseinfos {
  width: 694px;
  margin-left: 20px;
}
.course-pan {
  display: block;
}
.course-pan >>> .el-tabs__nav {
  height: 45px;
}
.course-pan >>> .el-tabs__item {
  height: 40px;
  border: 1px solid #ff7b33;
  border-radius: 5px;
  margin: 0 10px;
  color: #ff7b33;
  font-size: 14px;
  padding: 0 5px;
}
.course-pan >>> .el-tabs__nav-wrap::after {
  background-color: #fff;
}
.course-pan >>> .el-tabs__active-bar {
  background-color: #fff;
}
.course-pan >>> .el-tabs__item.is-active {
  color: #fff;
  background-color: #ff7b33;
  border-radius: 4px;
}
.items-ct {
  margin: 10px 10px;
  width: 630px;
}
.items-js {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
}
.items-video {
  margin-top: 20px;
}
.items-video p {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 25px;
}
.StudentList {
  margin-top: 20px;
  width: 1126px;
}
</style>
