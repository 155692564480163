/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-04-23 10:00:18
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-04-28 17:00:10 classdataStatistics
 */
import config from './config/class'
import axios from '@/utils/request'
export const classList = params => axios.post(config.classList, params)
export const classStatus = params => axios.post(config.classStatus, params)
export const classdataStatistics = params => axios.get(config.classdataStatistics, params)
export const classCreate = params => axios.post(config.classCreate, params)
export const classUpdate = params => axios.post(config.classUpdate, params)
export const classInfo = params => axios.post(config.classInfo, params)
export const classhasCourse = params => axios.post(config.classhasCourse, params)
export const addOwnCourse = params => axios.post(config.addOwnCourse, params)
export const courseDelete = params => axios.post(config.courseDelete, params)
export const courseSingDelete = params => axios.post(config.courseSingDelete, params)
export const classPlans = params => axios.post(config.classPlans, params)
export const studentinfo = params => axios.post(config.studentinfo, params)
export const classConfirm = params => axios.post(config.classConfirm, params)
export const classcancel = params => axios.post(config.classcancel, params)
export const studyHistory = params => axios.post(config.studyHistory, params)
export const headteacherList = params => axios.post(config.headteacherList, params)
export const classDetail = params => axios.post(config.classDetail, params)
export const changeClassStatus = params => axios.post(config.changeClassStatus, params)
export const studentInfo = params => axios.post(config.studentInfo, params)
export const classfinish = params => axios.post(config.classfinish, params)

export const classSelect = params => axios.post(config.classSelect, params)
export const classStudents = params => axios.post(config.classStudents, params)
export const studentsByOrg = params => axios.post(config.studentsByOrg, params)
export const studentsByClass = params => axios.post(config.studentsByClass, params)
export const studentsClasses = params => axios.post(config.studentsClasses, params)
export const studentsRemove = params => axios.post(config.studentsRemove, params)
export const studentsAdd = params => axios.post(config.studentsAdd, params)
export const classCourses = params => axios.post(config.classCourses, params)
export const classCourseAddOwn = params => axios.post(config.classCourseAddOwn, params)
export const classSettleCourseList = params => axios.post(config.classSettleCourseList, params)
export const classRemove = params => axios.post(config.classRemove, params)
export const classOffer = params => axios.post(config.classOffer, params)
