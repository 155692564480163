<template>
  <div class="coursePlans-list">
    <table-list
      title="班级列表"
      :loading="loading"
      :data="record"
      :search-form="searchForm(this)"
      :columns="columns(this)"
      :pager="pager"
      :btns="btns(this)"
      :options="{ selection: true }"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
// 顶部按钮
const btns = _this => [
  {
    label: '导出学习记录',
    method: _this.handleExceltable,
  },
  {
    label: '导出学员学习详情',
    method: _this.handleExport,
  },
]
// 搜索框配置
const searchForm = () => [
  {
    label: '学员名称',
    prop: 'studentName',
  },
  {
    label: '学员手机号',
    prop: 'mobile',
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'studentName',
    label: '学员名称',
  },
  {
    prop: 'phone',
    label: '手机号码',
  },
  {
    prop: 'firstStudyTime',
    label: '首次开始学习时间',
  },
  {
    prop: 'continuityStudyMax',
    label: '连续学习最大天数',
  },
  {
    prop: 'studyLengthMin',
    label: '已学时长（分钟）',
  },
  {
    prop: 'lastStudyTime',
    label: '最后一次学习时间',
  },
]
// 表格列配置
const Detailcolumns = () => [
  {
    prop: 'studentName',
    label: '学员名称',
  },
  {
    prop: 'courseName',
    label: '课程名称',
  },
  {
    prop: 'sectionName',
    label: '章节名称',
  },
  {
    prop: 'videoDuration',
    label: '章节时长',
  },
  {
    prop: 'firstBeginTime',
    label: '首次开始学习时间',
  },
]
import TableList from '@/components/TableList'
import to from 'await-to'
import { studentInfo, studyHistory } from '@/api/class'
export default {
  name: 'StudyInfo',
  components: {
    TableList,
  },
  data() {
    return {
      btns,
      searchForm,
      columns,
      loading: false,
      record: [],
      selectRecord: [],
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      classId: this.$route.query.classId,
      queryParams: {},
      Detailcolumns,
    }
  },
  mounted() {
    this.StudentData()
  },
  methods: {
    //获取学员列表
    async StudentData() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        studentInfo({ current, size, classId: this.classId, ...this.queryParams }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.record = res.data.list
      this.pager.total = res.data.total
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.StudentData()
    },
    async handleExceltable() {
      if (!this.selectRecord.length) return this.$message.warning('请先选择学员')
      // 导出表格的表头设置
      let allColumns = columns(this)
      var columnNames = []
      var columnValues = []
      for (var i = 0; i < allColumns.length; i++) {
        columnNames[i] = allColumns[i].label
        columnValues[i] = allColumns[i].prop
      }
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/vendor/Export2Excel')
        const tHeader = columnNames
        const filterVal = columnValues
        const list = this.selectRecord
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '学习记录')
      })
    },
    async handleExport() {
      if (!this.selectRecord.length) return this.$message.warning('请先选择学员')
      let obj = {
        classId: this.classId,
        studentIdList: [],
      }
      this.selectRecord.map(item => obj.studentIdList.push(item.studentId))
      const [res, err] = await to(studyHistory(obj))
      if (err) return this.$message.warning(err.msg)
      // 导出表格的表头设置
      let allColumns = Detailcolumns(this)
      var columnNames = []
      var columnValues = []
      for (var i = 0; i < allColumns.length; i++) {
        columnNames[i] = allColumns[i].label
        columnValues[i] = allColumns[i].prop
      }
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/vendor/Export2Excel')
        const tHeader = columnNames
        const filterVal = columnValues
        const list = res.data
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '学习详情记录')
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 多选
    handleSelectionChange(val) {
      this.selectRecord = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.StudentData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.StudentData()
    },
  },
}
</script>
<style scoped>
.coursePlans-list >>> .el-button.el-button--default {
  width: auto;
  padding: 0 20px;
}
</style>
