<template>
  <div class="class-info">
    <div class="classinfos">
      <el-card class="hei">
        <div slot="header" class="clearfix">
          <span class="title">{{ '班级信息' }}</span>
        </div>
        <div class="details">
          <img :src="classInf.pic" />
          <!-- <p>班级编号：{{ classInf.classId }}</p> -->
          <p>班级名称：{{ classInf.className }}</p>
          <p>班级人数：{{ classInf.classSize }}</p>
          <p v-for="(item, index) in classInf.headTeacher" :key="index">
            班主任：{{ item.studentName }}
          </p>
          <p>培训时间：{{ classInf.classBeginTime }}~{{ classInf.classEndTime }}</p>
          <p>班级介绍：{{ classInf.introduction }}</p>
        </div>
      </el-card>
    </div>
    <div class="courseinfos">
      <el-card class="hei">
        <div slot="header" class="clearfix">
          <span class="title">{{ '课程信息' }}</span>
        </div>
        <el-table :data="course" border style="width: 100%">
          <el-table-column prop="courseName" label="课程名称" width="163"> </el-table-column>
          <el-table-column prop="courseCategory" label="课程类型" width="163"> </el-table-column>
          <el-table-column prop="durationCount" label="总时长（分钟）" width="163">
          </el-table-column>
          <el-table-column prop="sectionsTotal" label="章节数（节）" width="163"> </el-table-column>
        </el-table>
        <span class="item-bn" @click="confirmclass">确认班级信息</span>
      </el-card>
    </div>
  </div>
</template>
<script>
import to from 'await-to'
import { classInfo, classhasCourse, classConfirm } from '@/api/class'
export default {
  data() {
    return {
      classId: this.$route.query.classId,
      //0-编辑中(待开班)，1-待培训（待上架），2-培训中，3-已结训（已完成）
      status: this.$route.query.status,
      statusText: '',
      classInf: {},
      course: [],
      pager: {
        total: 0,
        size: 100,
        current: 1,
        classId: this.$route.query.classId,
      },
    }
  },
  mounted() {
    if (this.status == 0) {
      this.statusText = '待开班'
    } else if (this.status == 1) {
      this.statusText = '待培训'
    } else if (this.status == 2) {
      this.statusText = '培训中'
    } else if (this.status == 3) {
      this.statusText = '已培训'
    }
    this.ClassinfoData()
    this.getCourse()
  },
  methods: {
    //获取班级信息
    async ClassinfoData() {
      const [res, err] = await to(classInfo({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.classInf = res.data
    },
    //获取课程信息
    async getCourse() {
      if (this.loading) return
      this.loading = true
      const [res, err] = await to(classhasCourse({ ...this.pager }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.course = res.data.list
      this.pager.total = res.data.total
    },
    //确认班级
    async confirmclass() {
      const [, err] = await to(classConfirm({ classId: this.classId }))
      if (err) return this.$message.warning(err.msg)
      this.$message.success('确认班级成功')
      this.$router.push('/course/class')
    },
  },
}
</script>
<style scoped>
.class-info {
  display: flex;
}
.hei {
  height: 100%;
}
.classinfos {
  width: 412px;
}
.details {
  margin-left: 20px;
}
.details img {
  width: 282px;
  height: 145px;
  margin-bottom: 20px;
}
.details p {
  font-size: 14px;
  font-family: Source Han Sans CN, Source Han Sans CN-Medium;
  font-weight: 500;
  text-align: left;
  color: #333333;
  line-height: 20px;
}
.courseinfos {
  width: 694px;
  margin-left: 20px;
  text-align: center;
}
.courseinfos >>> .el-table th.is-leaf,
.el-table td {
  text-align: center;
}
.courseinfos >>> .el-table tr {
  background-color: #fff;
}
.courseinfos >>> .el-table--enable-row-transition .el-table__body td {
  text-align: center;
}
.item-bn {
  display: inline-block;
  width: 110px;
  height: 40px;
  background: #ff7b33;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 40px;
  cursor: pointer;
  margin-top: 50px;
}
</style>
